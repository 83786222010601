import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/back.svg'


const _hoisted_1 = { class: "modal full" }
const _hoisted_2 = { class: "d-flex align-items-center mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_rules_text = _resolveComponent("rules-text")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
        style: {"height":"20px"}
      })
    ]),
    _createVNode(_component_rules_text)
  ]))
}