
import { Options, Vue } from 'vue-class-component';
import { StorageServices } from '@/services/StorageServices';
import { Watch } from 'vue-property-decorator';
import { siteUrl } from '@/config';
import RulesModal from '../modals/rulesModal.vue';
import store from '@/store';
import * as OM from '@/Model';

@Options({
    components: {}
})
export default class Welcome extends Vue {

    selectedLanguage: string = "";
    availableLanguages: OM.TextIdentifier[] = [];

    created() {
        var language = this.$route.query.language ? this.$route.query.language.toString() : '';
        if(!language)
            language = store.state.loggedUser.preferredCulture;

        this.selectedLanguage = language;
        this.availableLanguages = this.$localizationService.getEnabledLanguageList();

        if(store.state.loggedUser.identifier)
            this.$router.push('/profile');
    }

    mounted() {
        if(window.location.href.indexOf("?language") > -1) {
            const { search } = window.location;
            var onlyLanguageParam = search.substring(0, 15);
            const redirectUrl = siteUrl + "#/" + onlyLanguageParam;
            window.location.href = redirectUrl;
        }
    }

    @Watch('selectedLanguage')
    updateLanguage() {
        this.setLanguage();
    }

    setLanguage(){
        var loggedUser = store.state.loggedUser;
        loggedUser.preferredCulture = this.selectedLanguage;

        StorageServices.setLoggedUser(loggedUser);
        store.state.loggedUser = loggedUser;
    }

    openRulesModal() {
        this.$opModal.show(RulesModal, {})
    }

}
